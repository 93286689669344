<template>
	<v-app>
		<v-navigation-drawer v-model="drawer" app>
			<v-list-item>
				<v-list-item-avatar>
					<v-img src="@/assets/logo-acm.png"></v-img>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title class="text-header-3">
						Gestión de Regantes
					</v-list-item-title>
					<v-list-item-subtitle>
						Asociacion Canal Maule
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<MenuWatchmanArea v-if="activeMenu == 'limited'" />
			<MenuHelptableArea v-else-if="activeMenu && activeMenu == 'helptable'" />
			<MenuAdministrationArea v-else-if="activeMenu && activeMenu == 'administration'" />
			<MenuComunicationsArea v-else-if="activeMenu && activeMenu == 'communications'" />
			<MenuLegalArea v-else-if="activeMenu && activeMenu == 'legal'" />
			<MenuFieldArea v-else-if="activeMenu && activeMenu == 'fieldm'" />
			<MenuSystemAdmin v-else-if="activeMenu && activeMenu == 'nolimit'" />
		</v-navigation-drawer>

		<v-app-bar color="white" app flat class="top-bar">
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-spacer></v-spacer>
			<!-- <TopBarNotifications class="notifications" /> -->
			<TopBarUser class="profile" />
			<v-progress-linear indeterminate absolute bottom v-if="isLoading"></v-progress-linear>
		</v-app-bar>

		<!-- Sizes your content based upon application components -->
		<v-main class="grey lighten-4">
			<!-- Provides the application the proper gutter -->
			<v-container fluid>
				<v-slide-y-transition mode="out-in">
					<v-alert id="alert" v-show="alertShow.show" max-width="350px" dense :type="alertShow.type">{{
						alertShow.message }}</v-alert>
				</v-slide-y-transition>
				<!-- If using vue-router -->
				<router-view></router-view>
			</v-container>
		</v-main>

		<v-overlay :absolute="true" :value="disconnected" z-index="100" opacity="0.8">
			<v-card>
				<v-card-text class="text-center">
					<p>
						Has iniciado sesión desde otro dispositivo o navegador.
					</p>
					<v-btn color="primary" @click="reload"> Cerrar </v-btn>
				</v-card-text>
			</v-card>
		</v-overlay>
	</v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import MenuSystemAdmin from '@/components/MenuSystemAdmin';
import MenuWatchmanArea from '@/components/MenuWatchmanArea';
import MenuHelptableArea from '@/components/MenuHelptableArea';
import MenuLegalArea from '@/components/MenuLegalArea';
import MenuAdministrationArea from '@/components/MenuAdministrationArea.vue';
import MenuComunicationsArea from '@/components/MenuComunicationsArea.vue';
import MenuFieldArea from '@/components/MenuFieldArea.vue';

import TopBarUser from '@/components/TopBarUser';
//import TopBarNotifications from "@/components/TopBarNotifications";

export default {
	name: 'system-page',

	data() {
		return {
			drawer: true,
		};
	},

	components: {
		TopBarUser,
		//TopBarNotifications,
		MenuSystemAdmin,
		MenuWatchmanArea,
		MenuHelptableArea,
		MenuLegalArea,
		MenuAdministrationArea,
		MenuComunicationsArea,
		MenuFieldArea
	},

	watch: {
		user: {
			handler(newvalue) {
				/* Cuando cambia el objeto user, y su valor antiguo era false, entonces inicio sesion */
				if (newvalue.id) {
					this.connectSocket();
				}
			},
			deep: true,
		},
	},

	computed: {
		...mapState({
			isLoading: state => state.systemNotification.isLoading,
			user: state => state.userControl.user,
			alert: state => state.systemNotification.alert,
			disconnected: state => state.userControl.disconnected,
		}),

		activeMenu() {
			let code = '';
			if (this.user.association_area) {
				code = this.user.association_area.code;

				if (code == 'watchman' || code == 'sectionm') {
					code = 'limited';
				} else if (code == 'helptable') {
					code = 'helptable';
				} else if (code == 'administracion') {
					code = 'administracion';
				} else if (code == 'administracionm') {
					code = 'nolimit';
				} else if (code == 'communications') {
					code = 'communications';
				}
				else if (code == 'fieldm') {
					code = 'fieldm';
				}
				else if (code == 'generalm') {
					code = 'nolimit';
				} else if (code == 'legal') {
					code = 'legal';
				}
				// else if (code == 'operationsm'){
				// 	code = 'operationsm';
				// }
				else {
					code = 'nolimit';
				}
			}
			return code;
		},

		alertShow() {
			const defaultAlert = {
				type: '',
				message: '',
				show: false,
			};
			return this.alert && this.alert.type
				? this.alert
				: defaultAlert;
		},
	},

	mounted() {
		const storageName = process.env.VUE_APP_STORAGE_NAME;
		this.loadAssociationAreas();

		if (this.$utils.getItemStorage(storageName)) {
			/* Si se cuenta con token, se valida que el token aun esté vigente */
			this.isTheUserActive().catch(() => {
				this.$router.push({ name: 'login' });
			});
		}
	},

	methods: {
		...mapActions('userControl', [
			'loadAssociationAreas',
			'isTheUserActive',
			'connectSocket',
		]),

		reload() {
			location.reload();
		},
	},
};
</script>

<style lang="scss" scoped>
.top-bar {
	padding-right: 1rem;

	border-bottom-color: rgba(0, 0, 0, 0.12) !important;
	border-bottom-style: solid;
	border-bottom-width: 1px;

	.profile {
		margin-left: 1rem;
	}
}

.ppd-mark {
	text-align: center;

	p {
		margin-top: -14px;
	}
}

#alert {
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 100;
}

.main-background-color{
	background-color: #f5f5f7 !important;
}

</style>
