<template>
	<v-list dense nav>
		<v-list-item-group v-model="item" color="primary" mandatory>
			<v-list-item v-for="item in items" :key="item.title" link @click="goTo(item.link)">
				<v-list-item-icon>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item link @click="goToOut()">
				<v-list-item-icon>
					<v-icon>mdi-exit-run</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>Salir</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>

<script>
export default {
	name: 'menu-admin-area',
	data() {
		return {
			item: 0,
			items: [
				{
					title: 'Inicio',
					icon: 'mdi-view-dashboard-outline',
					link: 'dashboard',
				},
				{
					title: 'Información Maule',
					icon: 'mdi-weather-partly-rainy',
					link: 'acm-info',
				},
				{
					title: 'Ebook legal rol ACM',
					icon: 'mdi-scale-balance',
					link: 'file-legal',
				},
				{
					title: 'Notificar',
					icon: 'mdi-cellphone-information',
					link: 'acm-notification',
				},
				{
					title: 'Regantes',
					icon: 'mdi-watering-can-outline',
					link: 'irrigator',
				},
				{
					title: 'Celadores',
					icon: 'mdi-police-badge-outline',
					link: 'watchman',
				},
				{
					title: 'Pagos recibidos',
					icon: 'mdi-account-cash-outline',
					link: 'payments-reported',
				},
				{
					title: 'Eventos',
					icon: 'mdi-face-agent',
					link: 'events',
				},
				{
					title: 'Usuarios',
					icon: 'mdi-account-supervisor',
					link: 'userlist',
				},
				{
					title: 'Limnímetros',
					icon: 'mdi-ruler',
					link: 'limnimeters',
				},
				{
					title: 'Lecturas',
					icon: 'mdi-table-large',
					link: 'readings',
				},
			],
		};
	},
	methods: {
		goTo(routeName) {
			if (this.$route.name != routeName)
				this.$router.push({ name: routeName });
		},
		goToOut() {
			localStorage.clear();
			location.reload();
			this.$router.push({ name: 'login' });
		},
	},
};
</script>
